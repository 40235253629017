'use client';

import { usePathname } from 'next/navigation';
import { PAGE_TYPES, usePageType as useGTMPageType } from '@vaimo/google-tag-manager';
import {
    PAGE_TYPES as PAGE_TYPES_MONETATE,
    usePageType as usePageTypeMonetate,
} from '@vaimo/monetate/src/integrations/usePageType';
import { useUserContext } from '@/lib/context';

const AemIntegrations = ({ isHomePage }: { isHomePage?: boolean }): null => {
    const [{ isSignedIn }] = useUserContext();
    const pathname = usePathname();

    useGTMPageType({ isSignedIn, pageType: isHomePage ? PAGE_TYPES.HOME : 'other', pathname });
    usePageTypeMonetate({ pageType: isHomePage ? PAGE_TYPES_MONETATE.HOME : 'other' });

    return null;
};

export default AemIntegrations;
