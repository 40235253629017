'use client';

import React from 'react';
import RichContent from '@/components/RichContent';
import mergeClasses from '@/utils/Object/shallowMerge';
import defaultClasses from './cmsAccordion.module.scss';

interface ICMSAccordion {
    classes?: Record<string, string>;
    data: string;
}

export const CMSAccordion = ({ classes: propsClasses, data }: ICMSAccordion) => {
    const classes = mergeClasses(defaultClasses, propsClasses);

    const handleAccordionClick = (e: { target: HTMLElement }) => {
        const affectedElement = e.target.nextElementSibling;

        if (
            e.target.classList.contains('active-collapse') ||
            (e.target.classList.value.includes('inactive-collapse') &&
                !e.target.classList.value.includes('active-collapse'))
        ) {
            e.target.classList.toggle('active-collapse');

            if (affectedElement) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                affectedElement.style.display = 'none';
            }
        } else {
            e.target.classList.toggle('active-collapse');

            if (affectedElement) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                affectedElement.style.display = 'block';
            }
        }
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <div className={classes.root} onClick={handleAccordionClick}>
            <RichContent html={data} />
        </div>
    );
};
